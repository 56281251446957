import ReactInputMask from '@mona-health/react-input-mask';
import { TextField } from '@mui/material';
import valid from 'card-validator';
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';

import {
    StyledBody,
    StyledTextField,
    StyledCardInfo,
    StyledCardRowText,
    StyledSubTitle,
    StyledExpCVVSection,
    StyledCardButtonSection,
    StyledInfoText,
    StyledErrorTextBlock,
} from 'src/components/add-credit-card/add-credit-card.styled';
import { CreditCardIcon } from 'src/components/add-credit-card/components/credit-card-icon';
import { AppInput } from 'src/components/app-input';
import { AppButton } from 'src/components/button';

import { CREDIT_CARD_VALIDATOR_REGEX } from 'src/configuration/validators';

import { TEXT_VARS } from 'src/i18n/en';

import { SecureCard } from 'src/store/user/models';

interface CreditCardEmptyFormProps {
    customerId: string;
    handleSaveButtonAction: Function;
    handleDeleteButtonAction: Dispatch<SetStateAction<boolean>>;
    errorCardTextMsg: string;
    setErrorCardTextMsg: React.Dispatch<React.SetStateAction<string>>;
}

export const CreditCardEmptyForm: React.FC<CreditCardEmptyFormProps> = ({
    customerId,
    handleSaveButtonAction,
    handleDeleteButtonAction,
    errorCardTextMsg,
    setErrorCardTextMsg,
}) => {
    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cvc, setCvc] = useState('');
    const [expMonth, setExpMonth] = useState<number>(0);
    const [expYear, setExpYear] = useState<number>(0);
    const [expDate, setExpDate] = useState<string>('');
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [isCardNumberInputError, setIsCardNumberInputError] = useState<boolean>(false);
    const [isCVCInputError, setIsCVCInputError] = useState<boolean>(false);
    const [isExpDateInputError, setIsExpDateInputError] = useState<boolean>(false);

    const cardNumberValidationObject = useMemo(() => {
        return valid.number(cardNumber);
    }, [cardNumber]);

    const cardData: SecureCard = useMemo(() => {
        return {
            cardNumber,
            cardholderName,
            cvc,
            expMonth,
            expYear,
        };
    }, [cardNumber, cardholderName, cvc, expMonth, expYear]);

    const handleInputCardNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCardNumber(e.target.value);
    }, []);

    const handleInputCVVChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCvc(e.target.value);
    }, []);

    const _expDate = useMemo(() => {
        return { expMonth: expDate.slice(0, 2), expYear: expDate.slice(5, 7) };
    }, [expDate]);

    const isCardNumberValid = useMemo(() => {
        const _isNumberValid = cardNumberValidationObject.isValid;
        setIsCardNumberInputError(!_isNumberValid);
        if (isFirstRender) {
            return true;
        }
        return _isNumberValid;
    }, [cardNumberValidationObject.isValid, isFirstRender]);

    const isCardExpDateValid = useMemo(() => {
        const _isExpDateValid = (() => {
            if (expMonth > 12) {
                return false;
            }
            const currentDate = new Date();

            // expire year is in format: @number XX
            // expire month is in format: @number XX (from 1 to 12)
            const expireCardDate = new Date(expYear + 2000, expMonth - 1);

            return expireCardDate > currentDate;
        })();

        setIsExpDateInputError(!_isExpDateValid);
        if (isFirstRender) {
            return true;
        }
        return _isExpDateValid;
    }, [expMonth, expYear, isFirstRender]);

    const isCardCVCValid = useMemo(() => {
        const _isCVCValid = parseInt(cvc) < 1000 && cvc.length === 3;

        setIsCVCInputError(!_isCVCValid);
        if (isFirstRender) {
            return true;
        }

        return _isCVCValid;
    }, [cvc, isFirstRender]);

    const handleInputExpDateChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setExpDate(e.target.value as string);
    }, []);

    useEffect(() => {
        setExpMonth(parseInt(_expDate.expMonth));
        setExpYear(parseInt(_expDate.expYear));
    }, [_expDate.expMonth, _expDate.expYear]);

    const handleSaveButtonClick = useCallback(() => {
        setIsFirstRender(false);

        if (isCardNumberInputError || isCVCInputError || isExpDateInputError) {
            return;
        }

        handleSaveButtonAction(customerId, cardData);
    }, [
        cardData,
        customerId,
        handleSaveButtonAction,
        isCVCInputError,
        isCardNumberInputError,
        isExpDateInputError,
    ]);

    const handleDeleteButtonClick = useCallback(() => {
        handleDeleteButtonAction(false);
    }, [handleDeleteButtonAction]);

    useEffect(() => {
        setErrorCardTextMsg('');
    }, [setErrorCardTextMsg, cardholderName, cardNumber, expDate, cvc]);

    const cardNumberHelperText = useMemo(() => {
        return isCardNumberValid ? undefined : TEXT_VARS.ERRORS.YOUR_CARD_IS_INVALID;
    }, [isCardNumberValid]);

    const cardExpDateHelperText = useMemo(() => {
        return isCardExpDateValid ? undefined : TEXT_VARS.ERRORS.DATE_IS_INVALID;
    }, [isCardExpDateValid]);

    const cardCVVHelperText = useMemo(() => {
        return isCardCVCValid ? undefined : TEXT_VARS.ERRORS.CVC_IS_INVALID;
    }, [isCardCVCValid]);

    return (
        <>
            <StyledSubTitle>New Card</StyledSubTitle>
            <StyledInfoText>{TEXT_VARS.COMMON_TEXT.THE_CONVENIENCE_OF_SAVING}</StyledInfoText>

            <StyledBody>
                <CreditCardIcon cardNumberObject={cardNumberValidationObject} className="card-icon" />
                <StyledCardInfo>
                    <StyledCardRowText>
                        <span>{TEXT_VARS.COMMON_TEXT.NAME_ON_CARD}</span>
                        <AppInput
                            className="input"
                            firstRender={isFirstRender}
                            handleInputChange={setCardholderName}
                            inputErrorText={TEXT_VARS.ERRORS.PLEASE_ENTER_CARD_HOLDER_NAME}
                            inputValidationRegexp={CREDIT_CARD_VALIDATOR_REGEX}
                            inputValue={cardholderName}
                            placeholder="Jane Doe"
                            variant="standard"
                        />
                    </StyledCardRowText>
                    <StyledCardRowText>
                        <span>{TEXT_VARS.COMMON_TEXT.CARD_NUMBER}</span>

                        <ReactInputMask
                            // max card length - 19 digits
                            mask="9999999999999999999"
                            maskChar={null}
                            onChange={handleInputCardNumberChange}
                            value={cardNumber}
                        >
                            <StyledTextField
                                error={!isCardNumberValid}
                                helperText={cardNumberHelperText}
                                placeholder="************ 1234"
                            />
                        </ReactInputMask>
                    </StyledCardRowText>
                    <StyledExpCVVSection>
                        <StyledCardRowText>
                            <span>{TEXT_VARS.COMMON_TEXT.EXP_DATE}</span>
                            <ReactInputMask
                                mask="99 / 99"
                                maskChar={null}
                                onChange={handleInputExpDateChange}
                                value={expDate}
                            >
                                <TextField
                                    className="exp-cvv-text"
                                    error={!isCardExpDateValid}
                                    helperText={cardExpDateHelperText}
                                    placeholder="MM / YY"
                                    type="text"
                                />
                            </ReactInputMask>
                        </StyledCardRowText>
                        <StyledCardRowText>
                            <span>{TEXT_VARS.COMMON_TEXT.CVV}</span>
                            <ReactInputMask
                                mask="999"
                                maskChar={null}
                                onChange={handleInputCVVChange}
                                value={cvc}
                            >
                                <StyledTextField
                                    className="exp-cvv-text"
                                    error={!isCardCVCValid}
                                    helperText={cardCVVHelperText}
                                    placeholder="123"
                                />
                            </ReactInputMask>
                        </StyledCardRowText>
                    </StyledExpCVVSection>
                    <StyledErrorTextBlock className="error-message">{errorCardTextMsg}</StyledErrorTextBlock>

                    <StyledCardButtonSection>
                        <AppButton
                            appButtonType="Secondary"
                            className="save-button"
                            onClick={handleSaveButtonClick}
                        >
                            {TEXT_VARS.BUTTON.SAVE}
                        </AppButton>
                        <AppButton
                            appButtonType="Secondary"
                            className="delete-button"
                            onClick={handleDeleteButtonClick}
                        >
                            {TEXT_VARS.BUTTON.CANCEL}
                        </AppButton>
                    </StyledCardButtonSection>
                </StyledCardInfo>
            </StyledBody>
        </>
    );
};
