import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import { getAuth, onAuthStateChanged, setPersistence, inMemoryPersistence } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { useEffect, useMemo, useRef } from 'react';

import 'src/assets/css/firebase/fb.css';

import { signIn } from 'src/store/user/actions.thunks';

import { StyledTitleWrapper } from './firebase-sign-in.styled';

import 'firebaseui/dist/firebaseui.css';

const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
        {
            provider: EmailAuthProvider.PROVIDER_ID,
            fullLabel: 'Continue with email',
        },
        {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                // Forces account selection even when one account
                // is available.
                prompt: 'select_account',
            },
            fullLabel: 'Continue with Google',
        },
        {
            provider: 'apple.com',
            fullLabel: 'Continue with Apple',
        },
    ],
    callbacks: {
        signInSuccessWithAuthResult: () => false,
    },
    credentialHelper: 'none',
    tosUrl: 'https://billmybank.com/terms-of-use/',
    privacyPolicyUrl: 'https://billmybank.com/privacy-policy/',
};

export default function FirebaseSignIn() {
    const elementRef = useRef(null);

    const firebaseAuth = useMemo(() => getAuth(), []);

    useEffect(() => {
        // Get or Create a firebaseUI instance.
        const firebaseUiWidget =
            firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
        if (uiConfig.signInFlow === 'popup') firebaseUiWidget.reset();

        // We track the auth state to reset firebaseUi if the user signs out.
        const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, user => {
            if (user) {
                setPersistence(firebaseAuth, inMemoryPersistence).then(() => signIn(user));
            } else firebaseUiWidget.reset();
        });
        elementRef.current && firebaseUiWidget.start(elementRef.current, uiConfig);

        return () => {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
    }, [firebaseAuth]);

    return (
        <StyledTitleWrapper>
            <div ref={elementRef} />
        </StyledTitleWrapper>
    );
}
