import currency from 'currency.js';
import { useCallback, useMemo } from 'react';

import {
    StyledAccountBottomSection,
    StyledAccountInfo,
    StyledAccountNameNumberSection,
    StyledAccountTypeSection,
    StyledBankAccount,
    StyledError,
    StyledNameSection,
} from 'src/components/bank-account/bank-account.styled';
import { AppRadioButton } from 'src/components/radiobutton';

import { BankAccount, BankAccountInfoDTO } from 'src/store/accounts/models';

type Props = {
    bankAccount: BankAccount | BankAccountInfoDTO;
    isRadioButtonChecked: boolean | null;
    onClick(account: BankAccount | BankAccountInfoDTO): void;
    handleDeleteButtonAction?: (bankAccountId: string) => void;
};

export const BankAccountComponent = ({
    bankAccount,
    onClick,
    isRadioButtonChecked,
    handleDeleteButtonAction,
}: Props) => {
    const { institution, accountNumber, type, balance } = useMemo(() => bankAccount, [bankAccount]);
    const handleClick = useCallback(() => onClick(bankAccount), [bankAccount, onClick]);

    const handleDeleteButtonClick = useCallback(() => {
        handleDeleteButtonAction && handleDeleteButtonAction(bankAccount.id as string);
    }, [bankAccount.id, handleDeleteButtonAction]);

    return (
        <>
            <StyledBankAccount $isUpToDate={bankAccount.isUpToDate}>
                {isRadioButtonChecked != null && (
                    <AppRadioButton checked={isRadioButtonChecked} onClick={handleClick} />
                )}
                <StyledAccountInfo>
                    <StyledAccountNameNumberSection>
                        <span>{institution.name}</span>
                        <span>{accountNumber}</span>
                    </StyledAccountNameNumberSection>
                    <StyledAccountTypeSection>
                        <span>{type}</span>
                        {/*TODO balance is hidden*/}
                        <span>{currency(balance).format()}</span>
                    </StyledAccountTypeSection>
                    <StyledAccountBottomSection>
                        <StyledNameSection>{bankAccount.name}</StyledNameSection>
                        {/*<AppButton*/}
                        {/*    onClick={handleDeleteButtonClick}*/}
                        {/*    appButtonType={'Secondary'}*/}
                        {/*    className={'delete-button'}*/}
                        {/*>*/}
                        {/*    {TEXT_VARS.BUTTON.DELETE}*/}
                        {/*</AppButton>*/}
                    </StyledAccountBottomSection>
                    {!bankAccount.isUpToDate && (
                        <StyledError>
                            Data is outdated. Please login to your bank and select current account.
                        </StyledError>
                    )}
                </StyledAccountInfo>
            </StyledBankAccount>
        </>
    );
};
