import Lottie from 'react-lottie';

import animationData from 'src/assets/img/lottie/falling-coins.json';

import { TEXT_VARS } from 'src/i18n/en';

import { StyledText } from 'src/screens/not-enough-funds/not-enough-funds.styled.ts';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

type Props = { errMsg?: string };

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const NotEnoughFunds = ({ errMsg }: Props) => (
    <StyledPageWithPaddingContainer>
        <Lottie height={300} options={defaultOptions} width={300} />
        <StyledText>{errMsg || TEXT_VARS.COMMON_TEXT.WALLET_IS_EMPTY}</StyledText>
    </StyledPageWithPaddingContainer>
);
