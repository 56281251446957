import styled from '@emotion/styled';

import { TOP_BAR_HEIGHT } from 'src/theme/global-styles';

export const StyledAppRootContainer = styled.div`
    // padding-top: ${TOP_BAR_HEIGHT}px; // commented by Mike Petrusenko Jan 13th 2021
    // padding-top: calc(env(safe-area-inset-top) + ${TOP_BAR_HEIGHT}px); // commented by Mike Petrusenko Jan 13th 2021
    flex: 1;
    display: flex;
    flex-direction: column;
`;
