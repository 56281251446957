import { HistoryRouter } from 'redux-first-history/rr6';
import { history } from 'src/store';

import Header from 'src/components/header';

import { StyledAppRootContainer } from './router.styled';
import { AppRouter } from './user-router';

export const CustomerRouter = () => (
    <HistoryRouter history={history}>
        <StyledAppRootContainer className="app-root-container-classname">
            <Header />
            <AppRouter />
        </StyledAppRootContainer>
    </HistoryRouter>
);
