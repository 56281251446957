import { CardNumberVerification } from 'card-validator/dist/card-number';

import AMEX from 'src/assets/img/amex.svg?react';
import PlaceholderIcon from 'src/assets/img/credit-card-placeholder-icon.svg?react';
import DinersClub from 'src/assets/img/diners-club.svg?react';
import Discover from 'src/assets/img/discover.svg?react';
import JBC from 'src/assets/img/jcb.svg?react';
import MasterCard from 'src/assets/img/mastercard.svg?react';
import VisaSVG from 'src/assets/img/visa.svg?react';

import { StyledCreditCardIcon } from 'src/components/add-credit-card/components/credit-card-icon.styled';

type Props = {
    cardNumberObject: CardNumberVerification;
    className?: string;
};

export const CreditCardIcon = ({ cardNumberObject, className }: Props) => {
    const isVisa = cardNumberObject.card && cardNumberObject.card.type === 'visa';
    const isMasterCard = cardNumberObject.card && cardNumberObject.card.type === 'mastercard';
    const isAmericanExpress = cardNumberObject.card && cardNumberObject.card.type === 'american-express';
    const isDinersClub = cardNumberObject.card && cardNumberObject.card.type === 'diners-club';
    const isDiscover = cardNumberObject.card && cardNumberObject.card.type === 'discover';
    const isJCB = cardNumberObject.card && cardNumberObject.card.type === 'jcb';

    const unknownCard =
        !isVisa && !isMasterCard && !isAmericanExpress && !isDinersClub && !isDiscover && !isJCB && true;

    return (
        <StyledCreditCardIcon className={className}>
            {isVisa && <VisaSVG className="credit-card-svg" />}
            {isMasterCard && <MasterCard className="credit-card-svg" />}
            {isAmericanExpress && <AMEX className="credit-card-svg" />}
            {isDinersClub && <DinersClub className="credit-card-svg" />}
            {isDiscover && <Discover className="credit-card-svg" />}
            {isJCB && <JBC className="credit-card-svg" />}
            {unknownCard && <PlaceholderIcon className="credit-card-svg" />}
        </StyledCreditCardIcon>
    );
};
