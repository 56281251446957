import { TEXT_VARS } from 'src/i18n/en';

import {
    StyledCardData,
    StyledCardComponent,
    StyledTextBlock,
    StyledBorder,
    StyledLinkButton,
    StyledRowBlock,
    StyledSubtitle,
    StyledSubtitleSection,
} from 'src/screens/confirmation/components/card-component.styled';
import { CreditCardIcon } from 'src/screens/confirmation/components/credit-card-icon';

import { Card } from 'src/store/cards/models';

interface CardComponentProps {
    creditCard: Card;
    handleCardChange(): void;
    errorMessage?: string;
}

export const CardComponent = ({ creditCard, handleCardChange, errorMessage }: CardComponentProps) => (
    <>
        <StyledRowBlock>
            <StyledSubtitleSection>
                <StyledSubtitle className={errorMessage ? 'error' : ''}>
                    {TEXT_VARS.COMMON_TEXT.CARD_NUMBER}
                </StyledSubtitle>
            </StyledSubtitleSection>
            <StyledLinkButton onClick={handleCardChange}>
                {TEXT_VARS.COMMON_TEXT.CHANGE.toLowerCase()}
            </StyledLinkButton>
        </StyledRowBlock>
        <StyledBorder />
        <StyledCardComponent>
            <CreditCardIcon cardType={creditCard.cardType} />
            <StyledCardData>
                <StyledTextBlock className={errorMessage ? 'error' : ''}>
                    <div>{TEXT_VARS.COMMON_TEXT.CARD_NUMBER}</div>
                    <div>{creditCard.redactedNumber.slice(creditCard.redactedNumber.length - 8)}</div>
                </StyledTextBlock>
                <StyledTextBlock className={errorMessage ? 'error' : ''}>
                    <div>{TEXT_VARS.COMMON_TEXT.EXPIRATION_DATE}</div>
                    <div>{`${creditCard.expMonth}/${creditCard.expYear}`}</div>
                </StyledTextBlock>
                {errorMessage && <StyledTextBlock className="error-message">{errorMessage}</StyledTextBlock>}
            </StyledCardData>
        </StyledCardComponent>
    </>
);
