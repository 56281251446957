import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import accountReducer from 'src/store/accounts/reducer';
import applicationReducer from 'src/store/app/reducer';
import cardReducer from 'src/store/cards/reducer';
import configReducer from 'src/store/config/reducer';
import institutionReducer from 'src/store/institution/reducer';
import paymentReducer from 'src/store/payment/reducer';
import { ApplicationState } from 'src/store/root-reducer.models';
import userReducer from 'src/store/user/reducer';

import packageInfo from '../../package.json';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

function saveToSessionStorage(state: ApplicationState) {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (e) {
        console.log(e);
    }
}

function isOpenedInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function loadFromSessionStorage() {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return;
        }

        if (isOpenedInIframe()) {
            return JSON.parse(serializedState);
        }

        if (typeof sessionStorage.version_app === 'undefined' || sessionStorage.version_app === null) {
            sessionStorage.setItem('version_app', packageInfo.version);
        }

        if (sessionStorage.version_app != packageInfo.version) {
            sessionStorage.clear();
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const persistedState: ApplicationState | undefined = loadFromSessionStorage();

function configureStoreCustom() {
    const store = configureStore({
        reducer: {
            app: applicationReducer,
            user: userReducer,
            institutions: institutionReducer,
            accounts: accountReducer,
            cards: cardReducer,
            payment: paymentReducer,
            config: configReducer,
            router: routerReducer,
        },
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware),
        enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
        devTools: !import.meta.env.PROD,
        preloadedState: persistedState,
    });

    store.subscribe(() => saveToSessionStorage(store.getState()));

    return store;
}

export const store = configureStoreCustom();
export const history = createReduxHistory(store);
