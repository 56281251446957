import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigationType } from 'react-router-dom';

import { Alerts } from 'src/components/alerts';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useGoApp } from 'src/hooks/useGoApp.ts';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import SignIn from 'src/screens/sign-in';

import { setWelcomeMode } from 'src/store/app/reducer';
import { selectPaymentCode, selectWelcomeMode } from 'src/store/app/selectors';
import { ApplicationModes } from 'src/store/config/models';
import { setAppMode } from 'src/store/config/reducer';
import { cancelPayment, getFinancingOptions } from 'src/store/payment/actions.thunks';
import { FinancingOption, PaymentMode } from 'src/store/payment/models';
import { clearPayments, saveFinancingOption } from 'src/store/payment/reducer';
import { selectFinancingOption, selectPreparePaymentResponse } from 'src/store/payment/selectors';
import { generateJWTToken, signOut } from 'src/store/user/actions.thunks';
import { saveFinancingOptions, saveGeneratedJWTToken, storeQueryParams } from 'src/store/user/reducer';
import { selectJWTToken, selectQueryParams, selectSignInState } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { StyledText, StyledTextSection, StyledTitle } from './buy-now-pay-later.styled';

const SIGN_IN_CANCEL_CALLBACK = 'cancelCallback';
const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

const createCombinedApiTokenFromParams = function (apiToken: string | null, merchantId: string | null) {
    return `${apiToken}${merchantId ? ':' + merchantId : ''}`;
};

export const BuyNowPayLater = () => {
    const dispatch = useDispatch();
    const route = useAppRoute();

    const isMountedRef = useMountedRef();

    const paymentCode = useSelector(selectPaymentCode);
    const queryParamsSelector = useSelector(selectQueryParams);
    const preparePaymentSelector = useSelector(selectPreparePaymentResponse);
    const financingOptionSelector = useSelector(selectFinancingOption);
    const isWelcomeFlow = useSelector(selectWelcomeMode);
    const generatedJWTToken = useSelector(selectJWTToken);
    const isUserAuthenticated = useSelector(selectSignInState);

    const [cancelPaymentAction] = useThunkAction(cancelPayment);

    let { search } = useLocation();
    let navigationType = useNavigationType();

    const queryParams = window.location.search;
    const params = new URLSearchParams(queryParams);
    const amount = params.get('amount');
    const currencyParam = params.get('currency');
    const orderId = params.get('orderId');
    const description = params.get('description');
    const backUrl = params.get('backUrl');
    const merchantId = params.get('merchantId');
    const apiToken = params.get('apiToken');
    const apiKey = params.get('apiKey');
    const paymentMode = params.get('paymentMode');
    const appMode = params.get('appMode');
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');
    const phone = params.get('phone');
    const email = params.get('email');
    const combinedApiToken = createCombinedApiTokenFromParams(apiToken, merchantId);

    const [isGetWidgetSettingLoading, getWidgetSettingsError] = useGoApp();

    useEffect(() => {
        if (!search) {
            route(AppRoutes.generalError);
        }
    }, [route, search]);

    const _backUrl = useMemo(() => {
        if (backUrl && backUrl.includes('http')) {
            return backUrl;
        } else if (backUrl && backUrl !== 'undefined') {
            return `https://${backUrl}`;
        } else return '';
    }, [backUrl]);

    const isQueryError = useMemo(() => {
        if (queryParamsSelector) {
            return false;
        }
        return !amount || !combinedApiToken || parseFloat(amount) <= 0;
    }, [amount, combinedApiToken, queryParamsSelector]);

    const paymentModeValue = useMemo(() => {
        return paymentMode && (paymentMode === 'DIRECT_ONLY' || paymentMode === 'BNPL_ONLY')
            ? paymentMode
            : 'DEFAULT';
    }, [paymentMode]);

    useEffect(() => {
        if ((amount && combinedApiToken) || (amount && apiKey)) {
            dispatch(
                storeQueryParams({
                    apiToken: combinedApiToken,
                    ...(apiKey && { apiKey: apiKey }),
                    amount,
                    currency: currencyParam || TEXT_VARS.COMMON_TEXT.USD,
                    orderId: orderId || '',
                    description: description || TEXT_VARS.COMMON_TEXT.PURCHASE,
                    backUrl: _backUrl,
                    paymentMode: paymentModeValue,
                    merchantId: merchantId || '',
                    firstName,
                    lastName,
                    email,
                    phone,
                })
            );
        }
    }, [
        _backUrl,
        amount,
        combinedApiToken,
        currencyParam,
        description,
        dispatch,
        email,
        firstName,
        lastName,
        merchantId,
        orderId,
        paymentModeValue,
        phone,
        apiKey,
    ]);

    useEffect(() => {
        if (appMode) {
            dispatch(setAppMode(appMode as ApplicationModes));
        }
    }, [appMode, dispatch]);

    useEffect(() => {
        if (preparePaymentSelector || financingOptionSelector) {
            dispatch(clearPayments());
        }
    }, [dispatch, financingOptionSelector, preparePaymentSelector]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.top?.postMessage({ cancelAction: SIGN_IN_CANCEL_CALLBACK }, '*');
    }, []);

    const handleCancelClick = useCallback(() => {
        return cancelPaymentAction({
            payment: {
                amount: amount || '',
                currency: currencyParam || '',
                description: description || '',
                merchantOrderId: orderId || '',
            },
        });
    }, [amount, cancelPaymentAction, currencyParam, description, orderId]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await handleCancelClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [handleCancelClick]
    );
    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    const [
        financingOptionsAction,
        [isFinancingOptionsLoading],
        [financingOptionsError, setFinancingOptionsError],
    ] = useThunkAction(getFinancingOptions);

    const [generateJWTTokenAction, [isGenerateJWTTokenLoading], [generateJWTTokenError]] =
        useThunkAction(generateJWTToken);

    const [financingError, setFinancingError] = useState<string>('');

    const [financingOption, setFinancingOption] = useState<string>('');
    const [financingOptions, setFinancingOptions] = useState<string[]>();

    useEffect(() => {
        if (isWelcomeFlow) {
            return;
        }
        if (!financingOption) {
            return;
        }

        const decodedFinancingOption: FinancingOption = jwtDecode(financingOption);
        const isDirectPay = decodedFinancingOption.bnplMerchantId === null;

        !isDirectPay && dispatch(saveFinancingOption(financingOption));
    }, [dispatch, financingOption, route, isWelcomeFlow]);

    const [merchantAuthToken, setMerchantAuthToken] = useState('');

    useEffect(() => {
        if (apiToken) {
            setMerchantAuthToken(apiToken);
            dispatch(saveGeneratedJWTToken({ expiresIn: 0, idToken: apiToken, refreshToken: '' }));
        }
    }, [apiToken, dispatch]);

    useEffect(() => {
        if (apiToken) {
            return;
        }

        if (generatedJWTToken) {
            setMerchantAuthToken(generatedJWTToken.idToken);
            return;
        }

        apiKey &&
            generateJWTTokenAction({ apiKey }).then(r => {
                if (!isMountedRef.current || !r.success) {
                    return;
                }

                setMerchantAuthToken((r.payload as { idToken: string }).idToken);
            });
    }, [apiKey, apiToken, generateJWTTokenAction, isMountedRef, generatedJWTToken]);

    useEffect(() => {
        if (!amount || !merchantAuthToken || !paymentModeValue || isUserAuthenticated) {
            return;
        }

        financingOptionsAction({
            amount: amount,
            paymentMode: paymentModeValue as PaymentMode,
        }).then(r => {
            if (!r.success && r.error.message.response?.status === 405) {
                setFinancingOptionsError(undefined);
                setFinancingError(r.error.message.response.data.detail);
                return;
            }

            if (!r.success) return;

            const payload = r.payload as string[];

            setFinancingOptions(payload);
            dispatch(saveFinancingOptions(payload));
            if (payload.length === 1) setFinancingOption(payload[0]);
        });
    }, [
        merchantAuthToken,
        amount,
        financingOptionsAction,
        paymentMode,
        paymentModeValue,
        setFinancingOptionsError,
        dispatch,
        isUserAuthenticated,
    ]);

    useEffect(() => {
        if (isWelcomeFlow) {
            return;
        }

        if (Array.isArray(financingOptions) && financingOptions.length > 1) {
            dispatch(setWelcomeMode(true));
        }

        if (Array.isArray(financingOptions) && financingOptions.length === 1) {
            const finOption: FinancingOption = jwtDecode(financingOptions[0]);

            if (finOption.paymentFrequency && finOption.paymentFrequency === 'WELCOME') {
                dispatch(setWelcomeMode(true));
                return;
            }

            if (!finOption.financingProductId) {
                route(AppRoutes.customerSignIn);
                return;
            }
        }
    }, [isWelcomeFlow, financingOptions, route, dispatch, navigationType]);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!financingOptionsError || isQueryError || !!generateJWTTokenError || !!getWidgetSettingsError;
    }, [financingOptionsError, generateJWTTokenError, isQueryError, getWidgetSettingsError]);

    useEffect(() => {
        if (navigationType === 'POP' && paymentCode) {
            route(`/c/${paymentCode}`);
            return;
        }
    }, [navigationType, paymentCode, route]);

    return (
        <StyledPageWithPaddingContainer>
            <Loader
                isShowing={
                    isFinancingOptionsLoading || isGenerateJWTTokenLoading || isGetWidgetSettingLoading
                }
            />
            <StyledTitle>
                <span>{TEXT_VARS.TITLE.BUY_NOW_PAY_LATER}</span>
            </StyledTitle>
            {financingError && <Alerts alertType="warning" message={financingError} />}

            <StyledTextSection>
                <StyledText>{TEXT_VARS.COMMON_TEXT.SPLIT_THE_COST}</StyledText>
                <StyledText>{TEXT_VARS.COMMON_TEXT.ALL_YOU_NEED}</StyledText>
            </StyledTextSection>

            <SignIn />
            {isErrorDisplayed && (
                <InfoActionLayout
                    bodyText={financingOptionsError || TEXT_VARS.ERRORS.UNEXPECTED_ERROR}
                    buttonTitle={TEXT_VARS.COMMON_TEXT.CLOSE}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </StyledPageWithPaddingContainer>
    );
};
