import { FormControl } from '@mui/material';

import { FinancingOptions } from 'src/components/financing-options-radio/components/financing-options';
import {
    StyledFormLabel,
    StyledRadioContainer,
} from 'src/components/financing-options-radio/financing-options-radio.styled';

import { TEXT_VARS } from 'src/i18n/en';

type Props = {
    financingOption: string;
    setFinancingOption: React.Dispatch<React.SetStateAction<string>>;
    financingOptions: string[];
};

export const FinancingOptionsRadio = ({ financingOptions, financingOption, setFinancingOption }: Props) => (
    <StyledRadioContainer>
        <FormControl component="fieldset">
            <StyledFormLabel>{TEXT_VARS.TITLE.SELECT_FIN_OPTION}</StyledFormLabel>
            {financingOptions && (
                <FinancingOptions
                    financingOption={financingOption}
                    options={financingOptions}
                    setFinancingOption={setFinancingOption}
                />
            )}
        </FormControl>
    </StyledRadioContainer>
);
