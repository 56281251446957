import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { jwtDecode } from 'jwt-decode';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BackArrow from 'src/assets/img/replay_black_24dp.svg?react';
import WarningBlack from 'src/assets/img/warning_black_24dp.svg?react';

import { Alerts } from 'src/components/alerts';
import { BackButton } from 'src/components/back-button';
import { BankAccountComponent } from 'src/components/bank-account';
import { AppButton } from 'src/components/button';
import { ErrorPopupRedesign } from 'src/components/error-popup-redesign';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { StepBar } from 'src/components/step-bar';
import { Title } from 'src/components/title';

import { getApplicationConfig } from 'src/configuration/config';
import { BRANDS } from 'src/configuration/constants';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import {
    StyledBankAccounts,
    StyledBodySection,
    StyledBodyText,
    StyledBorderBetweenAccounts,
    StyledCancelButton,
    StyledConfirmButton,
    StyledSelectBankAccountWrapper,
    StyledStepBar,
    StyledSubTitle,
    StyledSubTitleSection,
} from 'src/screens/select-your-bank-account/select-your-bank-account.styled';

import { deleteBankAccountThunk } from 'src/store/accounts/actions.thunks';
import { BankAccount } from 'src/store/accounts/models';
import {
    clearAggregatedAccounts,
    clearSelectedAccount,
    saveSelectedBankAccount,
} from 'src/store/accounts/reducer';
import { selectAggregatedAccounts, selectSelectedBankAccount } from 'src/store/accounts/selectors';
import {
    selectChangeFlow,
    selectFirstRenderBankAcc,
    selectLinkFlow,
    selectWelcomeMode,
} from 'src/store/app/selectors';
import { selectBrandName } from 'src/store/config/selectors';
import { cancelPayment, preparePayment } from 'src/store/payment/actions.thunks';
import { FinancingOption } from 'src/store/payment/models';
import { selectFinancingOption } from 'src/store/payment/selectors';
import {
    addBankAccountToCustomer,
    getBankAccounts,
    getCurrentCustomer,
    getFiservWidgetSessionId,
    signOut,
} from 'src/store/user/actions.thunks';
import { CustomerDTO, FiServeToken } from 'src/store/user/models';
import { saveSelectFiservToken } from 'src/store/user/reducer';
import {
    selectBankAccounts,
    selectCustomerId,
    selectFiservToken,
    selectPrepayment,
    selectQueryParams,
} from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { ApplicationConfig } from 'src/types/applicationConfig.type';

const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

export const SelectYourBankAccount: React.FC = () => {
    const hostname = window.location.origin;
    const route = useAppRoute();
    const dispatch = useDispatch();
    const location = useLocation();
    const isMountedRef = useMountedRef();
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);

    const [isFiservWidgetLoading, setIsFiservWidgetLoading] = useState<boolean>(false);
    const [isTryAgainDisplayed, setIsTryAgainDisplayed] = useState(false);
    const [isHiccupDisplayed, setIsHiccupDisplayed] = useState(false);

    const fiFormRef = useRef<HTMLFormElement | null>(null);

    const selectedBankAccount = useSelector(selectSelectedBankAccount);
    const customerId = useSelector(selectCustomerId);
    const bankAccounts = useSelector(selectBankAccounts);
    const aggregatedAccounts = useSelector(selectAggregatedAccounts);
    const queryParams = useSelector(selectQueryParams);
    const financingOption = useSelector(selectFinancingOption);
    const brandName = useSelector(selectBrandName);
    const isWelcomeFlow = useSelector(selectWelcomeMode);
    const linkFlow = useSelector(selectLinkFlow);
    const isFirstRenderBankAcc = useSelector(selectFirstRenderBankAcc);
    const fiservToken = useSelector(selectFiservToken);
    const prepayment = useSelector(selectPrepayment);
    const isChangeFlow = useSelector(selectChangeFlow);

    const [addBankAccountAction, [isAddBankAccountLoading], [addBankAccountError, setAddAccountError]] =
        useThunkAction(addBankAccountToCustomer);

    const [
        deleteBankAccountAction,
        [isDeleteBankAccountActionLoading],
        [deleteBankAccountActionError, setDeleteBankAccountActionError],
    ] = useThunkAction(deleteBankAccountThunk);

    const [getBankAccountsAction, [isGetBankAccountsLoading], [getBankAccountsError]] =
        useThunkAction(getBankAccounts);

    const [_preparePaymentAction, [isPreparePaymentLoading], [preparePaymentError, setPreparePaymentError]] =
        useThunkAction(preparePayment);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError, setCancelPaymentError]] =
        useThunkAction(cancelPayment);

    const [
        fiservWidgetSessionIdAction,
        [isFiservWidgetSessionIdLoading],
        [fiservWidgetSessionIdError, setFiservWidgetSessionIdError],
    ] = useThunkAction(getFiservWidgetSessionId);

    const [getCurrentCustomerAction, [isGetCurrentCustomerLoading], [getCurrentCustomerError]] =
        useThunkAction(getCurrentCustomer);

    useEffect(() => {
        const gotAccount = aggregatedAccounts && aggregatedAccounts.length === 1;

        if (gotAccount && isFirstRenderBankAcc && isWelcomeFlow) {
            route(AppRoutes.customerReFinancing);
            return;
        }

        if (gotAccount && isFirstRenderBankAcc && isChangeFlow) {
            route(AppRoutes.changeConfirmation);
            return;
        }

        if (gotAccount && isFirstRenderBankAcc && linkFlow !== 'CONSENT_LINK') {
            route(AppRoutes.customerConfirmation);
            return;
        }
    }, [aggregatedAccounts, isFirstRenderBankAcc, isWelcomeFlow, linkFlow, route, isChangeFlow]);

    useEffect(() => {
        if (aggregatedAccounts && aggregatedAccounts.length === 1) {
            dispatch(saveSelectedBankAccount(aggregatedAccounts[0]));
        }
    }, [aggregatedAccounts, dispatch]);

    const sessionId = useMemo(() => {
        return fiservToken?.sessionId || '';
    }, [fiservToken]);

    const handlePlusIconButtonClick = useCallback(() => {
        sessionId && fiFormRef.current?.submit();
        setIsFiservWidgetLoading(true);
    }, [sessionId]);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        if (sessionId) {
            return;
        }

        fiservWidgetSessionIdAction({ id: customerId }).then(r => {
            if (!r.success) {
                setIsFiservWidgetLoading(false);
                return;
            }

            try {
                const serializedState = r.payload as FiServeToken;
                dispatch(saveSelectFiservToken(serializedState));
            } catch (e) {
                console.log(e);
            }
        });
    }, [customerId, dispatch, fiservWidgetSessionIdAction, sessionId]);

    const handleSelectBankAccount = useCallback(
        (bankAccount: BankAccount) => {
            dispatch(saveSelectedBankAccount(bankAccount));
        },
        [dispatch]
    );

    const isDirectPayment = useMemo(() => {
        if (!financingOption) {
            return true;
        }

        const decodedFinOption: FinancingOption = jwtDecode(financingOption);
        return !decodedFinOption.financingProductId;
    }, [financingOption]);

    const handleContinueClick = useCallback(async () => {
        if (isWelcomeFlow) {
            route(AppRoutes.customerReFinancing);
            return;
        }

        if (isChangeFlow) {
            route(AppRoutes.changeConfirmation);
            return;
        }

        if (!selectedBankAccount) {
            return;
        }

        if (!customerId) {
            return;
        }

        let isBankAccountAdded = bankAccounts?.find(account => account.id === selectedBankAccount.id);

        if (!isBankAccountAdded) {
            await addBankAccountAction({ customerId, accountId: selectedBankAccount.id }).then(async r => {
                if (!r.success) {
                    return;
                }
            });
        }

        if (linkFlow === 'CONSENT_LINK') {
            route(AppRoutes.consentConfirmation);
            return;
        }

        if (!queryParams) {
            return;
        }

        _preparePaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                merchantOrderId: queryParams.orderId || prepayment?.merchantOrderId || '',
                bankAccountId: selectedBankAccount.id,
                type: isDirectPayment ? 'SIMPLE' : 'FINANCING',
                ...(!isDirectPayment && { financingOption }),
            },
        }).then(r => {
            if (!isMountedRef.current) {
                return;
            }

            if (!r.success) {
                if (r.error?.message?.response && r.error.message.response?.status == 300) {
                    route(AppRoutes.customerReFinancing);
                    return;
                }
            }

            if (!r.success) {
                if (r.error?.message?.response && r.error.message.response?.status >= 500) {
                    setPreparePaymentError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }
                if (r.error?.message?.response && r.error.message.response?.status == 423) {
                    setPreparePaymentError(undefined);
                    setIsHiccupDisplayed(true);
                }
                return;
            }

            const payload = r.payload as { cardAuthMode: string };

            payload.cardAuthMode === 'NEVER'
                ? route(AppRoutes.customerConfirmation)
                : route(AppRoutes.customerSelectYourCard);
        });
    }, [
        isWelcomeFlow,
        selectedBankAccount,
        customerId,
        queryParams,
        bankAccounts,
        _preparePaymentAction,
        prepayment?.merchantOrderId,
        isChangeFlow,
        isDirectPayment,
        financingOption,
        route,
        addBankAccountAction,
        isMountedRef,
        setPreparePaymentError,
        linkFlow,
    ]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const handleDeleteBankAccountButtonAction = useCallback(
        (accountId: string) => {
            if (!customerId) {
                return;
            }

            deleteBankAccountAction({ customerId, accountId }).then(r => {
                if (!r.success) {
                    return;
                }

                if (selectedBankAccount && accountId === selectedBankAccount.id) {
                    dispatch(clearSelectedAccount());
                }

                if (bankAccounts) {
                    const isUserHasCurrentBankAccount: boolean = bankAccounts.some(acc => {
                        return acc.id === accountId;
                    });

                    if (!isUserHasCurrentBankAccount) {
                        dispatch(clearAggregatedAccounts());
                    }
                }
            });
        },
        [customerId, deleteBankAccountAction, selectedBankAccount, bankAccounts, dispatch]
    );

    const bankAccountComponent = useMemo(() => {
        return (
            aggregatedAccounts &&
            aggregatedAccounts.map((acc, index) => (
                <React.Fragment key={acc.id}>
                    <BankAccountComponent
                        bankAccount={acc}
                        handleDeleteButtonAction={handleDeleteBankAccountButtonAction}
                        isRadioButtonChecked={selectedBankAccount?.id === acc.id}
                        onClick={handleSelectBankAccount}
                    />
                    {aggregatedAccounts.length !== index + 1 && <StyledBorderBetweenAccounts />}
                </React.Fragment>
            ))
        );
    }, [
        selectedBankAccount,
        aggregatedAccounts,
        handleDeleteBankAccountButtonAction,
        handleSelectBankAccount,
    ]);

    const isButtonDisabled = useMemo(() => {
        return !selectedBankAccount || !selectedBankAccount.isUpToDate;
    }, [selectedBankAccount]);

    useEffect(() => {
        setAddAccountError(undefined);
        setDeleteBankAccountActionError(undefined);
        setPreparePaymentError(undefined);
        setCancelPaymentError(undefined);
        setAlertErrorMessage('');
        setFiservWidgetSessionIdError(undefined);
    }, [
        setPreparePaymentError,
        setAddAccountError,
        setDeleteBankAccountActionError,
        setCancelPaymentError,
        setFiservWidgetSessionIdError,
    ]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!(
            addBankAccountError ||
            preparePaymentError ||
            deleteBankAccountActionError ||
            fiservWidgetSessionIdError ||
            getBankAccountsError ||
            getCurrentCustomerError
        );
    }, [
        preparePaymentError,
        addBankAccountError,
        deleteBankAccountActionError,
        fiservWidgetSessionIdError,
        getBankAccountsError,

        getCurrentCustomerError,
    ]);

    const cancelPaymentButtonClick = useCallback(() => {
        if (!queryParams) {
            setAlertErrorMessage('QueryParams unavailable, but expected');
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                description: queryParams.description,
                merchantOrderId: queryParams.orderId,
                bankAccountId: selectedBankAccount?.id,
            },
        }).then(r => {
            if (!r.success) {
                return;
            }
            setIsCancelPaymentSuccess(true);
        });
    }, [selectedBankAccount?.id, cancelPaymentAction, queryParams]);

    const cancelPaymentPopupTitle = useMemo(() => {
        return isCancelPaymentSuccess
            ? TEXT_VARS.COMMON_TEXT.YOUR_ORDER_CANCELED
            : TEXT_VARS.COMMON_TEXT.CANCEL_PURCHASE;
    }, [isCancelPaymentSuccess]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await cancelPaymentButtonClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [cancelPaymentButtonClick]
    );

    const isBrandChargee = useMemo(() => {
        return brandName === BRANDS.CHARGEE;
    }, [brandName]);

    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    const fiServEnvUrl = useMemo(() => {
        const { fiServeWidgetBaseUrl }: ApplicationConfig = getApplicationConfig();
        return fiServeWidgetBaseUrl + '/alldata/?';
    }, []);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        getCurrentCustomerAction().then(r => {
            if (!r.success) return;

            const payload = r.payload as CustomerDTO;

            if ((!payload.accounts || payload.accounts.length === 0) && isFirstRenderBankAcc) {
                handlePlusIconButtonClick();
                return;
            }
        });

        getBankAccountsAction({ customerId: customerId });
    }, [
        customerId,
        getBankAccountsAction,
        getCurrentCustomerAction,
        handlePlusIconButtonClick,
        sessionId,
        isFirstRenderBankAcc,
    ]);

    useEffect(() => {
        if (location?.state?.from?.includes(AppRoutes.addAccounts) && selectedBankAccount) {
            handleContinueClick();
        }
    }, [handleContinueClick, location, selectedBankAccount]);

    const handleTryAgainButtonClick = useCallback(() => {
        setIsTryAgainDisplayed(false);
    }, []);

    useEffect(() => {
        if (sessionId && (!aggregatedAccounts || aggregatedAccounts.length === 0)) {
            setIsTryAgainDisplayed(true);
        }

        if (aggregatedAccounts && aggregatedAccounts.length) {
            setIsTryAgainDisplayed(false);
        }
    }, [sessionId, aggregatedAccounts]);

    return (
        <StyledSelectBankAccountWrapper>
            <StyledPageWithPaddingContainer>
                <Loader
                    isShowing={
                        isAddBankAccountLoading ||
                        isPreparePaymentLoading ||
                        isDeleteBankAccountActionLoading ||
                        isCancelPaymentLoading ||
                        isFiservWidgetSessionIdLoading ||
                        isGetBankAccountsLoading ||
                        isFiservWidgetLoading ||
                        isGetCurrentCustomerLoading
                    }
                />
                {sessionId && (
                    <form action={fiServEnvUrl} method="post" ref={fiFormRef} target="_self">
                        <input name="sessionToken" type="hidden" value={fiservToken?.sessionId} />
                        <input
                            name="return_url"
                            type="hidden"
                            value={`${hostname}/customer/add-bank-accounts/`}
                        />
                        <input name="error_url" type="hidden" value={`${hostname}/error-fiserv/`} />
                        <input
                            name="css_url"
                            type="hidden"
                            value="https://widget.billmybank.com/fiserv/fiserv-custom.css"
                        />
                    </form>
                )}
                <BackButton routeUrl={AppRoutes.customerEnterBasicInfo} />
                <Title
                    isChargee={isBrandChargee}
                    subtitle={TEXT_VARS.TITLE.OR_ADD_NEW_ONE}
                    title={TEXT_VARS.TITLE.SELECT_YOUR_BANK_ACCOUNT}
                />
                {!isBrandChargee && (
                    <StyledStepBar>
                        <StepBar step={2} />
                    </StyledStepBar>
                )}
                <StyledSubTitleSection $isChargee={isBrandChargee}>
                    <StyledSubTitle>{TEXT_VARS.TITLE.BANK_ACCOUNTS}</StyledSubTitle>
                    <Fab aria-label="add" onClick={handlePlusIconButtonClick} size="small">
                        <AddIcon />
                    </Fab>
                </StyledSubTitleSection>
                <StyledBodySection>
                    <StyledBankAccounts>{bankAccountComponent}</StyledBankAccounts>

                    {!aggregatedAccounts && bankAccounts?.length === 0 && (
                        <StyledBodyText>
                            {TEXT_VARS.COMMON_TEXT.PLEASE_ADD_A_BANK_ACCOUNT_TO_CONTINUE}
                        </StyledBodyText>
                    )}
                    {aggregatedAccounts &&
                        aggregatedAccounts.length === 0 &&
                        bankAccounts &&
                        bankAccounts.length === 0 && (
                            <StyledBodyText>
                                {TEXT_VARS.COMMON_TEXT.THERE_ARE_NO_AGGREGATED_BANK_ACCOUNTS}
                            </StyledBodyText>
                        )}
                </StyledBodySection>
                {alertErrorMessage && <Alerts alertType="error" message={alertErrorMessage} />}
                <StyledConfirmButton>
                    <AppButton
                        appButtonType="Continue"
                        disabled={isButtonDisabled}
                        onClick={handleContinueClick}
                    >
                        {TEXT_VARS.BUTTON.CONFIRM}
                    </AppButton>
                </StyledConfirmButton>
                <StyledCancelButton>
                    <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                        {TEXT_VARS.BUTTON.CANCEL}
                    </AppButton>
                </StyledCancelButton>
            </StyledPageWithPaddingContainer>
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={cancelPaymentButtonClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    titleText={cancelPaymentPopupTitle}
                    type="error"
                />
            )}

            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
            {isTryAgainDisplayed && (
                <ErrorPopupRedesign
                    bodyText={TEXT_VARS.COMMON_TEXT.SORRY_WE_COULD_NOT}
                    Icon={BackArrow}
                    labelText={TEXT_VARS.COMMON_TEXT.OOPS_WE_COULD_NOT}
                    onClick={handleTryAgainButtonClick}
                />
            )}
            {isHiccupDisplayed && (
                <ErrorPopupRedesign
                    bodyText={TEXT_VARS.COMMON_TEXT.WE_UNABLE_PROCESS_PAYMENT}
                    Icon={WarningBlack}
                    labelText={TEXT_VARS.COMMON_TEXT.OOPS_HICCUP}
                />
            )}
        </StyledSelectBankAccountWrapper>
    );
};
