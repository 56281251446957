import { useMemo } from 'react';

import iconPlaceholder from 'src/assets/img/icon-placeholder.png';

import {
    StyledBankAccountComponent,
    StyledBankAccountData,
    StyledBorder,
    StyledLinkButton,
    StyledRowBlock,
    StyledSubtitle,
    StyledTextBlock,
} from 'src/components/account/account-component.styled.ts';
import { Img } from 'src/components/img';

import { TEXT_VARS } from 'src/i18n/en.ts';

import { BankAccount } from 'src/store/accounts/models.ts';

type Props = {
    bankAccount: BankAccount;
    totalAmount: string;
    handleAccountChange(): void;
    errorMessage?: string;
};

export const AccountComponent = ({ bankAccount, totalAmount, handleAccountChange, errorMessage }: Props) => {
    const imgUrl = bankAccount.institution.smallLogoUrl || iconPlaceholder;

    const errorLowBalance = useMemo(
        () =>
            parseInt(totalAmount) > parseInt(bankAccount.balance)
                ? TEXT_VARS.ERRORS.THIS_CARD_HAS_AN_AVAILABLE_BALANCE_LESS
                : '',
        [bankAccount, totalAmount]
    );

    return (
        <>
            <StyledRowBlock>
                <StyledSubtitle $isChargee className={errorLowBalance || errorMessage ? 'error' : ''}>
                    {TEXT_VARS.COMMON_TEXT.BANK_ACCOUNT_INFORMATION}
                </StyledSubtitle>
                <StyledLinkButton onClick={handleAccountChange}>
                    {TEXT_VARS.COMMON_TEXT.CHANGE.toLowerCase()}
                </StyledLinkButton>
            </StyledRowBlock>

            <StyledBorder />

            <StyledBankAccountComponent>
                <Img className="bank-img" src={imgUrl} />
                <StyledBankAccountData>
                    <StyledTextBlock className={errorLowBalance || errorMessage ? 'error' : ''}>
                        <div>{bankAccount.institution.name}</div>
                        <div>{bankAccount.accountNumber}</div>
                    </StyledTextBlock>
                    <StyledTextBlock className={errorLowBalance || errorMessage ? 'error' : ''}>
                        <div>{bankAccount.type}</div>
                    </StyledTextBlock>
                    <StyledTextBlock className="error-message">
                        {errorLowBalance || errorMessage}
                    </StyledTextBlock>
                </StyledBankAccountData>
            </StyledBankAccountComponent>
        </>
    );
};
