import AMEX from 'src/assets/img/amex.svg?react';
import DinersClub from 'src/assets/img/diners-club.svg?react';
import Discover from 'src/assets/img/discover.svg?react';
import JBC from 'src/assets/img/jcb.svg?react';
import MasterCard from 'src/assets/img/mastercard.svg?react';
import VisaSVG from 'src/assets/img/visa.svg?react';

import { StyledCreditCardIcon } from 'src/screens/confirmation/components/credit-card-icon.styled';

interface CreditCardIconProps {
    cardType: string;
    className?: string;
}

export const CreditCardIcon = ({ cardType, className }: CreditCardIconProps) => {
    const isVisa = cardType === 'visa';
    const isMasterCard = cardType === 'mastercard';
    const isAmericanExpress = cardType === 'amex';
    const isDinersClub = cardType === 'diners';
    const isDiscover = cardType === 'discover';
    const isJCB = cardType === 'jcb';

    return (
        <StyledCreditCardIcon className={className}>
            {isVisa && <VisaSVG className="credit-card-svg" />}
            {isMasterCard && <MasterCard className="credit-card-svg" />}
            {isAmericanExpress && <AMEX className="credit-card-svg" />}
            {isDinersClub && <DinersClub className="credit-card-svg" />}
            {isDiscover && <Discover className="credit-card-svg" />}
            {isJCB && <JBC className="credit-card-svg" />}
        </StyledCreditCardIcon>
    );
};
