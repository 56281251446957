import axios from 'axios';

import { ENDPOINT } from 'src/configuration/endpoints';

import { saveFiLoginAccounts } from 'src/store/accounts/reducer';
import { AppThunk, resultError, resultSuccess } from 'src/store/root-reducer.models';

export const checkAccountUpdateCompleted: AppThunk<{ id: string; requestId: string }> =
    ({ id, requestId }) =>
    async () => {
        function runInterval() {
            let interval: NodeJS.Timeout;
            let count = 0;
            return new Promise<boolean>((resolve, reject) => {
                interval = setInterval(async () => {
                    try {
                        count++;
                        const status = await axios.get(ENDPOINT.checkAccountUpdate(id, requestId));
                        if (status.data === true) {
                            resolve(status.data);
                        } else if (count > 30) {
                            reject(status.data);
                        }
                    } catch (e) {
                        reject(e);
                    }
                }, 10000);
            }).finally(() => clearInterval(interval));
        }

        try {
            const result = await runInterval();
            return resultSuccess(result);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const deleteBankAccountThunk: AppThunk<{ customerId: string; accountId: string }> =
    ({ customerId, accountId }) =>
    async () => {
        try {
            await axios.delete(ENDPOINT.customerBankAccount(customerId, accountId));
        } catch (e) {
            return resultError({ message: e });
        }
        return resultSuccess();
    };

export const getIdpBankAccountsThunk: AppThunk<{ customerId: string; fiLoginAcctId: string }> =
    ({ customerId, fiLoginAcctId }) =>
    async dispatch => {
        try {
            const result = await axios.get(ENDPOINT.getIdpAccounts(customerId, fiLoginAcctId));
            dispatch(saveFiLoginAccounts(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const addBankAccount: AppThunk<{ bankAccountId: string; customerId: string }> =
    ({ bankAccountId, customerId }) =>
    async () => {
        try {
            await axios.post(ENDPOINT.addBankAccount(bankAccountId, customerId));
            return resultSuccess();
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const getCustomerLedgerAccountsThunk: AppThunk<{ customerId: string }> =
    ({ customerId }) =>
    async () => {
        try {
            const result = await axios.get(ENDPOINT.ledgerAccount(customerId));
            return resultSuccess(result.data.content);
        } catch (e) {
            return resultError({ message: e });
        }
    };
