import { Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alerts } from 'src/components/alerts';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useGoApp } from 'src/hooks/useGoApp.ts';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { generateJWTToken, getPaymentLinkDecoded, setPaymentLinkStatus } from 'src/store/user/actions.thunks';
import { PaymentLinkDTO } from 'src/store/user/models';
import { saveGeneratedJWTToken, savePaymentLink } from 'src/store/user/reducer';
import { selectPaymentLinkData } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { transformEnums } from 'src/utils/transformEnums';

import { StyledBody, StyledButtonSection } from './payment-link.styled';

export const PaymentLink: React.FC = () => {
    const dispatch = useDispatch();
    const widgetSdkLink = useRef('');
    const isMountedRef = useMountedRef();
    const route = useAppRoute();

    const paymentLinkData = useSelector(selectPaymentLinkData);
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);

    const [isGetWidgetSettingLoading, getWidgetSettingsError] = useGoApp();

    const [getPaymentLinkAction, [isPaymentLinkLoading], [paymentLinkError, setPaymentLinkError]] =
        useThunkAction(getPaymentLinkDecoded);

    const [
        changePaymentLinkStatusAction,
        [isChangePaymentLinkStatusLoading],
        [changePaymentLinkError, setChangePaymentLinkError],
    ] = useThunkAction(setPaymentLinkStatus);

    const [generateJWTTokenAction, [isGenerateJWTTokenLoading], [generateJWTTokenError]] =
        useThunkAction(generateJWTToken);

    const _paymentToken = window.location.pathname.replace('/l/', '');

    useEffect(() => {
        generateJWTTokenAction({ paymentLinkToken: _paymentToken }).then(r => {
            if (!r.success) {
                return;
            }

            getPaymentLinkAction({ token: _paymentToken }).then(r => {
                if (!isMountedRef.current || !r.success) return;

                const payload = r.payload as PaymentLinkDTO;
                dispatch(savePaymentLink(payload));
                dispatch(saveGeneratedJWTToken(payload.merchantToken));
            });
        });
    }, [
        _paymentToken,
        dispatch,
        generateJWTTokenAction,
        getPaymentLinkAction,
        isMountedRef,
        setPaymentLinkError,
    ]);

    useEffect(() => {
        if (paymentLinkData) {
            widgetSdkLink.current = `/buy-now-pay-later?apiToken=undefined&merchantId=${paymentLinkData.merchantId}&amount=${paymentLinkData.amount}&description=${paymentLinkData.description}&merchantOrderId=${paymentLinkData.merchantOrderId}`;
        }
    }, [paymentLinkData]);

    const handleDeclineButtonClick = useCallback(async () => {
        await changePaymentLinkStatusAction({ token: _paymentToken, status: 'DECLINED' }).then(r => {
            if (!r.success) {
                return;
            }
            setIsCancelPaymentSuccess(true);
        });
    }, [_paymentToken, changePaymentLinkStatusAction]);

    const handlePayButtonClick = useCallback(async () => {
        route(widgetSdkLink.current as AppRoutes);
    }, [route]);

    const errorMessage = useMemo(() => {
        return paymentLinkError || changePaymentLinkError || generateJWTTokenError || getWidgetSettingsError;
    }, [paymentLinkError, changePaymentLinkError, generateJWTTokenError, getWidgetSettingsError]);

    const infoTitle = useMemo(() => {
        if (!paymentLinkData) {
            return;
        }

        if (paymentLinkData.status === 'NEW') {
            return TEXT_VARS.COMMON_TEXT.TO_COMPLETE_YOUR_PURCHASE;
        } else if (paymentLinkData.status === 'PAID') {
            return TEXT_VARS.COMMON_TEXT.PAYMENT_LINK_COMPLETED_PURCHASE;
        } else if (paymentLinkData.status === 'DECLINED') {
            return TEXT_VARS.COMMON_TEXT.PAYMENT_LINK_DECLINED_PURCHASE;
        } else {
            return TEXT_VARS.COMMON_TEXT.PAYMENT_LINK_EXPIRED;
        }
    }, [paymentLinkData]);

    useEffect(() => {
        setPaymentLinkError(undefined);
        setChangePaymentLinkError(undefined);
    }, [setChangePaymentLinkError, setPaymentLinkError]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const cancelPaymentPopupTitle = useMemo(() => {
        return isCancelPaymentSuccess
            ? TEXT_VARS.COMMON_TEXT.YOUR_ORDER_CANCELED
            : TEXT_VARS.COMMON_TEXT.CANCEL_PURCHASE;
    }, [isCancelPaymentSuccess]);

    return (
        <StyledPageWithPaddingContainer>
            {errorMessage && <Alerts alertType="error" message={errorMessage} />}
            <Loader
                isShowing={
                    isPaymentLinkLoading ||
                    isChangePaymentLinkStatusLoading ||
                    isGenerateJWTTokenLoading ||
                    isGetWidgetSettingLoading
                }
            />

            {!errorMessage && (
                <StyledBody>
                    <span className="info-text">{infoTitle}</span>
                    <div className="payment-info-wrapper">
                        <div className="payment-info">
                            <div>
                                <span>Amount:&nbsp;</span>
                                <span className="amount-value">
                                    {paymentLinkData && `$${paymentLinkData.amount}`}
                                </span>
                            </div>
                            <span>Merchant:&nbsp;{paymentLinkData && paymentLinkData.merchantName}</span>
                            {paymentLinkData && paymentLinkData.description && (
                                <span>
                                    Description:&nbsp;
                                    {paymentLinkData.description}
                                </span>
                            )}
                            {paymentLinkData && paymentLinkData.merchantOrderId && (
                                <span>
                                    Order&nbsp;Id:&nbsp;
                                    {paymentLinkData.merchantOrderId}
                                </span>
                            )}
                            <span>
                                Status:&nbsp;
                                {paymentLinkData &&
                                    transformEnums('PAYMENT_LINK_STATUS', paymentLinkData.status)}
                            </span>
                        </div>
                    </div>
                </StyledBody>
            )}
            {paymentLinkData && (paymentLinkData.status === 'NEW' || paymentLinkData.status === 'OPENED') && (
                <StyledButtonSection>
                    <Button
                        className="button-aff"
                        color="primary"
                        disabled={!!errorMessage}
                        onClick={handlePayButtonClick}
                    >
                        <span className="save-text">PAY</span>
                    </Button>
                    <Button
                        className="button-negative"
                        color="primary"
                        disabled={!!errorMessage}
                        onClick={handleOpenCancelPaymentPopup}
                    >
                        <span className="cancel-text">DECLINE</span>
                    </Button>
                </StyledButtonSection>
            )}
            {isCancelPopupDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={handleDeclineButtonClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    titleText={cancelPaymentPopupTitle}
                    type="error"
                />
            )}
        </StyledPageWithPaddingContainer>
    );
};
