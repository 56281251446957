import { RadioProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';

import RoundButtonFullBMBSVG from 'src/assets/img/checked-svgrepo-bmb.svg?react';
import RoundButtonEmptySVG from 'src/assets/img/roundButtonEmpty.svg?react';

import { StyledAppRadioButton } from 'src/components/radiobutton/radiobutton.styled';

import { getApplicationConfig } from 'src/configuration/config';

import { AppColors } from 'src/theme/app-colors.ts';

const { brand } = getApplicationConfig();

interface Props extends RadioProps {
    setChecked?: Dispatch<SetStateAction<boolean>>;
    buttonColor?: string;
}

const getInputProps = (disabled?: boolean) => ({
    'aria-label': `primary radiobutton ${disabled ? 'disabled' : ''}`,
});

export const AppRadioButton = ({
    setChecked,
    disabled,
    onChange,
    className,
    buttonColor,
    ...otherProps
}: Props) => {
    const theme = useTheme();

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setChecked && setChecked(event.target.checked);
        },
        [setChecked]
    );

    return (
        <StyledAppRadioButton
            $buttonColor={buttonColor}
            checkedIcon={
                <RoundButtonFullBMBSVG
                    fill={
                        theme.brandTheme?.brandProperties?.primaryColor ||
                        (brand === 'BMB' ? AppColors.bmbPrimary : AppColors.greenButton)
                    }
                />
            }
            className={`${className || ''} app-radio-button`}
            disabled={disabled}
            icon={<RoundButtonEmptySVG />}
            inputProps={getInputProps(disabled)}
            onChange={setChecked ? handleChange : onChange}
            {...otherProps}
        />
    );
};
