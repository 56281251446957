import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { AppInputTextField, StyledAppInputComponent } from 'src/components/app-input/app-input.styled';

import { StyledExtendable } from 'src/utils/sharedModels';

interface AppInputComponentProps {
    appInputTitle?: string;
    className?: string;
    inputValue: string;
    label?: string;
    handleInputChange(value: string): void;
    inputErrorText?: string;
    inputValidationRegexp?: RegExp;
    firstRender?: boolean;
    setIsInputError?: (value: boolean) => void;
    isChargee?: boolean;
    setIsValidationError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppInput: React.FC<TextFieldProps & AppInputComponentProps & StyledExtendable> = ({
    className,
    appInputTitle,
    inputValue,
    label,
    handleInputChange,
    inputErrorText,
    inputValidationRegexp,
    firstRender,
    setIsInputError,
    isChargee,
    setIsValidationError,
    ...props
}) => {
    const [isFocused, setFocused] = useState(false);

    const handleInputValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(e.target.value);
        },
        [handleInputChange]
    );

    const handleFocus = useCallback(() => setFocused(true), [setFocused]);
    const handleBlur = useCallback(() => setFocused(false), [setFocused]);

    const validationState = useMemo(() => {
        const isValid = inputValidationRegexp ? inputValidationRegexp.test(inputValue) : false;
        const validationResult = isValid ? 'valid' : 'invalid';
        const validationResultIfNoFocus = isFocused ? undefined : validationResult;

        return isValid ? 'valid' : validationResultIfNoFocus;
    }, [inputValidationRegexp, inputValue, isFocused]);

    const isError = firstRender ? false : validationState === 'invalid';
    const helperText = isError ? inputErrorText : undefined;

    useEffect(() => {
        if (setIsInputError && validationState === 'invalid') {
            setIsInputError(true);
        } else setIsInputError && setIsInputError(false);
    }, [isError, setIsInputError, validationState]);

    useEffect(() => {
        setIsValidationError && setIsValidationError(validationState !== 'valid');
    }, [setIsValidationError, validationState]);

    return (
        <StyledAppInputComponent>
            {appInputTitle && <div className="app-input-title">{appInputTitle.toUpperCase()}</div>}
            <AppInputTextField
                className={className}
                error={isError}
                helperText={helperText}
                label={isChargee ? '' : label}
                onBlur={handleBlur}
                onChange={handleInputValueChange}
                onFocus={handleFocus}
                value={inputValue}
                variant="standard"
                {...props}
            />
        </StyledAppInputComponent>
    );
};
